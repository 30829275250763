<template>
  <div class="container">
    <!--    <category
          :searchType="searchType"
          @subjectId="subjectId"
          @secondList="secondList"
          :subjectInd="subjectInd"
          :subjectChilInd="subjectChilInd"
          :chilrenList="chilrenList"
          :levelsubJectId="levelsubJectId"
        ></category>-->
    <div class="hh_live_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <img src="@/assets/img/studentImg/home/live-icon.png"/>
            直播
          </p>
        </div>
        <live-com :type="'liveType'" :livedata="livedata" @getVerCode="getVerCode"></live-com>
        <div class="hh_span_wrap" v-if="livedata.length > 0">
          <router-link :to="{ name: 'liveList' , params: {chilrenList} }" class="hh_span">查看更多...</router-link>
        </div>
        <none-data v-else :searchType="searchType"></none-data>
      </div>
    </div>
    <div class="hh_course_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <img src="@/assets/img/studentImg/home/course-icon.png"/>
            点播
          </p>
        </div>
        <live-com :type="'dibbleType'" :dibbledata="dibbledata" @getVerCode="getVerCode"></live-com>
        <div class="hh_span_wrap" v-if="dibbledata.length > 0">
          <router-link to="/dibbleList" class="hh_span">查看更多...</router-link>
        </div>
        <none-data v-else :searchType="searchType"></none-data>
      </div>
    </div>
    <div class="hh_live_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <img src="@/assets/img/studentImg/home/live-icon.png"/>
            讲师
          </p>
        </div>
        <teacher-com :teacherData="lecturerDataList" @getVerCode="getVerCode"></teacher-com>
        <div class="hh_span_wrap" v-if="lecturerDataList.length > 0">
          <router-link :to="'/famousTeacher'" class="hh_span">查看更多...</router-link>
        </div>
        <none-data v-else :searchType="searchType"></none-data>
      </div>
    </div>
    <div class="hh_course_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <img src="@/assets/img/studentImg/home/exam-icon.png"/>
            考试
          </p>
        </div>
        <exam-com :examList="examList"></exam-com>
        <div class="hh_span_wrap exit" v-if="examList.length > 0">
          <router-link to="/exam" class="hh_span">查看更多...</router-link>
        </div>
        <none-data v-else :searchType="searchType"></none-data>
      </div>
    </div>
    <div class="hh_course_wrap">
      <div class="hh_live common_inner">
        <div class="hh_title">
          <p>
            <img src="@/assets/img/studentImg/home/exam-icon.png"/>
            资讯
          </p>
        </div>
          <consult-com :consultData="consultDataList" :search="true"></consult-com>
        <div class="hh_span_wrap exit" v-if="consultDataList.length > 0">
          <router-link to="/consultList" class="hh_span">查看更多...</router-link>
        </div>
        <none-data v-else :searchType="searchType"></none-data>
      </div>
    </div>
    <courseVerificationCode :courseId="courseId"/>
  </div>
</template>
<script>
import {fetchExamList} from '@/apis/studentsEndAPI/exam/index.js'
import noneData from '@comps/backStageComponent/noneData'
import API from '@/apis/studentsEndAPI/liveListAPI'
import liveCom from '@/components/studentsComponent/liveCom'
import teacherCom from "@comps/studentsComponent/teacherCom";
import consultCom from "@views/studentsEnd/consult/components/consultCom";
import examCom from '@/components/studentsComponent/examCom'
import category from '../studentsEnd/live/components/category'
import courseVerificationCode from "@/components/backStageComponent/courseVerificationCode";
import CommonUtils from "@/utils";
import lecturerAPI from "@apis/backStageEndAPI/lecturerAPI";
import consultAPI from "@/apis/backStageEndAPI/consultManageAPI";
export default {
  name: 'searchList',
  components: {
    category,
    liveCom,
    examCom,
    teacherCom,
    consultCom,
    noneData,
    courseVerificationCode
  },
  data() {
    return {
      courseId: '',
      searchType: 1,
      liveType: 1,
      dibbleType: 2,
      livedata: [],
      dibbledata: [],
      subjectInd: '',
      subjectChilInd: '',
      listData: [],
      examList: [],
      liveData: {
        courseType: 1, //课程类型
        page: 1,
        pageSize: 8,
        createStartTime: '',
        createEndTime: '',
        state: '',
        subjectId: '', //学科id
        keyword: '',
      },
      dibbleData: {
        courseType: 2, //课程类型
        page: 1,
        pageSize: 8,
        createStartTime: '',
        createEndTime: '',
        state: '',
        subjectId: '', //学科id
        keyword: '',
      },
      examData: {
        current: 1,
        size: 6,
        typeId: '',
        content: '',
      },
      chilrenList: {},
      lecturerForm: { //获取讲师参数
        page: 1,
        pageSize: 8,
        teaName: '',
      },
      lecturerDataList: [],
      levelsubJectId: '',
      consultData: {//资讯
        page: 1,
        pageSize: 8,
        newsTitle: "",
      },
      consultDataList: [],//资讯列表
    }
  },
  watch: {
    '$store.state.student.value': {
      handler(val) {
        this.liveData.keyword = val
        this.dibbleData.keyword = val
        this.examData.content = val
        this.lecturerForm.teaName = val
        this.consultData.newsTitle = val
        this.init()
        this.dibbleInit()
        this.examInit()
        this.getLecturerList()
        this.getConsultList()
        if (!val) {
          this.lecturerForm.teaName = ''
          this.getLecturerList()
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit('PARENTID', null)
    this.$store.commit('PARENTChiLD', null)
    this.$store.commit('SETALL', null)
  },
  mounted() {
    this.$EventBus.$emit('flag', true)
    if (this.$route.query.all) {
      this.init()
      this.dibbleInit()
      this.examInit()
      this.getLecturerList()
    }
    if (this.$store.state.student.value) {
      let val = this.$store.state.student.value
      this.liveData.keyword = val
      this.dibbleData.keyword = val
      this.examData.content = val
      this.lecturerForm.teaName = val
      this.consultData.newsTitle = val
      this.init()
      this.dibbleInit()
      this.examInit()
      this.getLecturerList()
      this.getConsultList()
    }
    if (this.$route.query.index) {
      this.liveData.subjectId = this.$route.query.medicalId
      this.dibbleData.subjectId = this.$route.query.medicalId
      this.subjectInd = this.$route.query.index
      this.levelsubJectId = this.$route.query.medicalId
      this.$store.commit('PARENTID', this.subjectInd)
      this.init()
      this.dibbleInit()
      this.examInit(this.dibbleData.subjectId)
    }
    if (this.$route.params.item) {//首页子级学科数据
      this.chilrenList = this.$route.params.item
      this.liveData.subjectId = this.$route.params.medicalId
      this.levelsubJectId = this.$route.params.item.medicalId
      this.dibbleData.subjectId = this.$route.params.medicalId
      this.subjectInd = this.$route.params.index
      this.subjectChilInd = this.$route.params.indexs
      this.$store.commit('PARENTID', this.subjectInd)
      this.$store.commit('PARENTChiLD', this.subjectChilInd)
      this.init()
      this.dibbleInit()
      this.examInit(this.dibbleData.subjectId)
    }
    if (sessionStorage.getItem('flag')) {
      this.init()
      this.dibbleInit()
      this.examInit()
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem('flag')
  },
  beforeRouteLeave(to, from, next) {
    if (to.fullPath == '/dibbleList') {
      if (this.dibbledata.length == 0) {
        sessionStorage.setItem('nodata', JSON.stringify(true))
      }
    }
    if (to.fullPath == '/liveList') {
      if (this.livedata.length == 0) {
        sessionStorage.setItem('nodata', JSON.stringify(true))
      }
    }
    next()
  },
  methods: {
    getVerCode(val) {
      this.courseId = val.courseId
      this.$store.state.isVerifi = true
    },
    getLecturerList() { //获取讲师
      lecturerAPI.getClientTeacher(CommonUtils.parseToParams(this.lecturerForm)).then(res => {
        if (res && !res.status) {
          if (res.dataList.length > 0) {
            sessionStorage.setItem('teacherData', JSON.stringify(res))
          } else {
            sessionStorage.removeItem('teacherData')
          }
          this.lecturerDataList = res.dataList
        }
      })
    },
    getConsultList() {//获取资讯
      consultAPI
        .studyConsultList(CommonUtils.parseToParams(this.consultData))
        .then((res) => {
          if (res && !res.status) {
            if (res.dataList.length > 0) {
              sessionStorage.setItem('consultDataList', JSON.stringify(res))
            } else {
              sessionStorage.removeItem('consultDataList')
            }
            this.consultDataList = res.dataList
          }
        });
    },
    async init() {
      let res = await API.getLiveList(CommonUtils.parseToParams(this.liveData))
      if (res && !res.status) {
        if (res.dataList.length > 0) {
          sessionStorage.setItem('livedata', JSON.stringify(res))
        } else {
          sessionStorage.removeItem('livedata')
        }
        this.livedata = res.dataList
      }
    },
    async dibbleInit() {
      let res = await API.getLiveList(CommonUtils.parseToParams(this.dibbleData))
      if (res && !res.status) {
        if (res.dataList.length > 0) {
          sessionStorage.setItem('dibbledata', JSON.stringify(res))
        } else {
          sessionStorage.removeItem('dibbledata')
        }
        this.dibbledata = res.dataList
      }
    },
    async examInit(subjectId) {
      const {data} = await fetchExamList({
        current: this.examData.current,
        size: this.examData.size,
        params: {
          state: '',
          typeId: Number(subjectId),
          title: this.$store.state.student.value
        },
      })
      if (data && !data.status) {
        if (data.records.length > 0) {
          sessionStorage.setItem('examList', JSON.stringify(data))
        } else {
          sessionStorage.removeItem('examList')
        }
        this.examList = data.records
      }
      // this.examList = data?.records || []
    },
    subjectId(subjectId, type) {
      if (subjectId) {
        this.liveData.subjectId = subjectId
        this.dibbleData.subjectId = subjectId
        this.examData.typeId = subjectId
        this.init()
        this.dibbleInit()
        this.examInit(this.examData.typeId)
      } else if (type == 'all') {
        this.liveData.subjectId = ''
        this.dibbleData.subjectId = ''
        this.examData.typeId = ''
        this.init()
        this.dibbleInit()
        this.examInit(this.examData.typeId)
      }
    },
    secondList(secondList) {
      sessionStorage.setItem('secondList', JSON.stringify(secondList))
    },
  },
}
</script>
<style lang='scss' scoped>
@import "src/assets/css/studentsEndCSS/home";
.container {
  width: 100%;
  background: #f7faff;
  .hh_live_wrap {
    background: #f7faff;
  }
  .hh_span_wrap {
    padding-top: 5px;
    text-align: center;
    .hh_nospan {
      padding: 6px 8px;
      border: 1px solid #999;
      border-radius: 6px;
      color: #999;
      font-size: 14px;
    }
    .hh_span {
      cursor: pointer;
      @extend .hh_nospan;
    }
  }
  .exit {
    padding: 20px 0 10px 0;
  }
}
::v-deep .hh_exam_wrap {
  margin-bottom: 0;
  background: none;
}
::v-deep .hh_container {
  .hh_wrap {
    padding-top: 0;
    img {
      margin: 0 26%;
      padding-bottom: 15px;
      width: 45%;
      height: 44%;
    }
  }
}
</style>
